import { CUSTOMER_TYPE_COMPANY } from "@/constants/customers";

export default {
  type: "suppliers",
  financial_number: null,
  tags: null,
  company_name: null,
  email: null,
  phone: null,
  country: "Canada",
  state: "Quebec",
  city: null,
  zipcode: null,
  address: null,
  relationshipNames: ["organization", "tags", "allowedLocations"],
  organization: {
    type: "organizations",
    id: null,
  },
  tags: [],
  allowedLocations: [],

  billing_entity_type: CUSTOMER_TYPE_COMPANY,
  billing_company_name: null,
  billing_firstname: null,
  billing_lastname: null,
  billing_country: "Canada",
  billing_state: "Quebec",
  billing_city: null,
  billing_zipcode: null,
  billing_address: null,
  billing_email: null,
};
