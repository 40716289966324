<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ `${supplier.company_name}` }} - {{ $t("COMMON.CONTACTS") }}
      </span>

      <div class="col-12 mt-4">
        <base-button
          type="primary"
          icon
          size="sm"
          @click="addSupplierContact()"
          v-if="$currentUserCan($permissions.PERM_CREATE_CONTACTS)"
        >
          <span class="btn-inner--icon">
            <i class="fas fa-plus"></i>
          </span>
          <span class="btn-inner--text">{{ $t("COMMON.ADD_CONTACT") }}</span>
        </base-button>
      </div>
    </div>
    
    <supplier-view-contacts-add-contact-modal
      :supplier="supplier"
      :showModal="showContactAddModal"
      @onCloseModal="onCloseModal"
    />

    <contact-list-table
      :filterOrganization="supplier.organization.id"
      :filterContactableType="`App\\Models\\Supplier`"
      :filterContactableId="supplier.id"
      :key="rerenderKey"
    />
  </div>
</template>

<script>
import ContactListTable from "../../ContactManagement/partials/ContactListTable.vue";
import SupplierViewContactsAddContactModal from "./SupplierViewContactsAddContactModal.vue";

export default {
  name: "supplier-view-contacts",

  components: { ContactListTable, SupplierViewContactsAddContactModal },

  props: ["supplier"],

  data() {
    return {
      showContactAddModal: false,
      rerenderKey: 0,
    };
  },

  computed: {},

  created() {},

  methods: {
    addSupplierContact() {
      this.showContactAddModal = true;
    },

    onCloseModal() {
      this.showContactAddModal = false;
      this.$emit("supplierUpdated", true);
      this.rerenderKey++;
    },
  },

  mounted() {},

  watch: {
    supplier(supplier) {},
  },
};
</script>
