<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <div v-if="!newContact">
      <base-input
        :label="`${$t('COMMON.CONTACT')}`"
        :placeholder="$t('COMMON.CONTACT')"
      >
        <contact-selector
          :contact="contact.contactable.id"
          :filterable="true"
          :showAll="false"
          :filterOrganization="supplier.organization.id"
          :allowCreate="true"
          @contactChanged="contactChanged"
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.contactable" />
    </div>

    <div v-if="newContact">
      <contact-form
        :loading="loading"
        :contactData="contact"
        :formErrors="formErrors"
        :hideOrganization="true"
        :hideContactable="true"
        @contactSubmitted="addSupplierContact"
      />
    </div>

    <template slot="footer">
      <button
        v-if="!newContact"
        type="submit"
        class="btn btn-primary"
        @click="linkSupplierContact"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("COMMON.LINK_CONTACT") }}
      </button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closeContactModal"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import ContactSelector from "@/components/ContactSelector.vue";
import defaultContact from "../../ContactManagement/defaultContact";
import ContactForm from "../../ContactManagement/partials/ContactForm.vue";

export default {
  name: "supplier-view-contacts-add-contact-modal",

  components: { ContactForm, ValidationError, ContactSelector },

  mixins: [formMixin],

  props: ["supplier", "showModal"],

  data() {
    const contactData = cloneDeep(defaultContact);
    return {
      contact: contactData,
      formErrors: null,
      loading: false,
      newContact: false,
    };
  },

  computed: {},

  created() {},

  methods: {
    contactChanged(contactId) {
      if (!parseInt(contactId)) {
        this.newContact = true;
        this.contact.id = null;
        this.contact.organization = this.supplier.organization;
        this.contact.contactable.type = "suppliers";
        this.contact.contactable.id = this.supplier.id;
      } else {
        this.contact.id = contactId;
      }
    },

    closeContactModal() {
      this.$emit("onCloseModal");
    },

    async linkSupplierContact() {
      this.loading = true;

      try {
        const contactData = {
          type: "contacts",
          id: this.contact.id,
          relationshipNames: ["contactable"],
          contactable: {
            type: "suppliers",
            id: this.supplier.id,
          },
        };
        await this.$store.dispatch("contacts/update", contactData);
        this.$notify({
          type: "success",
          message: this.$t("CONTACTS.CONTACT_ADDED"),
        });
        this.closeContactModal();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        const contact = await this.$store.getters["contacts/contact"];
        if (contact.id) {
          await this.$store.dispatch("contacts/destroy", contact.id);
        }
        this.loading = false;
      }
    },

    async addSupplierContact(contact) {
      this.loading = true;
      const contactData = cloneDeep(contact);
      delete contactData.id;

      try {
        await this.$store.dispatch("contacts/add", contactData);
        this.$notify({
          type: "success",
          message: this.$t("CONTACTS.CONTACT_ADDED"),
        });
        this.closeContactModal();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        const contact = await this.$store.getters["contacts/contact"];
        if (contact.id) {
          await this.$store.dispatch("contacts/destroy", contact.id);
        }
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {
    supplier(supplier) {},

    showModal(showModal) {
      if (!showModal) {
        const contactData = cloneDeep(defaultContact);
        this.contact = contactData;
        this.formErrors = null;
        this.loading = false;
        this.newContact = false;
      }
    },
  },
};
</script>
